import RouteBasePaths from 'constants/RouteBasePaths';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useCloseAuthenticatePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(() => {
    const bodyEl = document.getElementById('bodyContentWrapper');
    if (bodyEl && bodyEl.style.overflow === 'hidden') bodyEl.style.overflow = 'unset';
    if (location.state?.from) navigate(location.state.from.pathname);
    else navigate(RouteBasePaths.HomePage);
  }, [navigate, location]);
};
